import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import {
	MsalService,
	MsalBroadcastService,
	MSAL_GUARD_CONFIG,
	MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
	AuthenticationResult,
	EventMessage,
	EventType,
	InteractionStatus,
	InteractionType,
	PopupRequest,
	RedirectRequest,
} from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
	title = 'TMS Extensions';
	isIframe = false;
	loginDisplay = false;
	private readonly _destroying$ = new Subject<void>();

	constructor(
		@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
		private authService: MsalService,
		private msalBroadcastService: MsalBroadcastService
	) {}

	ngOnInit(): void {
		console.log('Environment', environment);

		this.isIframe = window !== window.parent && !window.opener;
		this.setLoginDisplay();

		this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

		/**
		 * You can subscribe to MSAL events as shown below. For more info,
		 * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
		 */

		this.msalBroadcastService.msalSubject$
			.pipe(
				filter(
					(msg: EventMessage) =>
						msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED
				)
			)
			.subscribe((result: EventMessage) => {
				if (this.authService.instance.getAllAccounts().length === 0) {
					window.location.pathname = '/';
				} else {
					this.setLoginDisplay();
				}
			});

		this.msalBroadcastService.inProgress$
			.pipe(
				filter((status: InteractionStatus) => status === InteractionStatus.None),
				takeUntil(this._destroying$)
			)
			.subscribe(() => {
				this.setLoginDisplay();
				this.checkAndSetActiveAccount();
			});
	}

	setLoginDisplay() {
		this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
	}

	checkAndSetActiveAccount() {
		/**
		 * If no active account set but there are accounts signed in, sets first account to active account
		 * To use active account set here, subscribe to inProgress$ first in your component
		 * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
		 */
		let activeAccount = this.authService.instance.getActiveAccount();

		if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
			let accounts = this.authService.instance.getAllAccounts();
			// add your code for handling multiple accounts here
			this.authService.instance.setActiveAccount(accounts[0]);
		}
	}

	login() {
		if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
			if (this.msalGuardConfig.authRequest) {
				this.authService
					.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
					.subscribe((response: AuthenticationResult) => {
						this.authService.instance.setActiveAccount(response.account);
					});
			} else {
				this.authService.loginPopup().subscribe((response: AuthenticationResult) => {
					this.authService.instance.setActiveAccount(response.account);
				});
			}
		} else {
			if (this.msalGuardConfig.authRequest) {
				this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
			} else {
				this.authService.loginRedirect();
			}
		}
	}

	logout() {
		const activeAccount =
			this.authService.instance.getActiveAccount() || this.authService.instance.getAllAccounts()[0];

		if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
			this.authService.logoutPopup({
				account: activeAccount,
			});
		} else {
			this.authService.logoutRedirect({
				account: activeAccount,
			});
		}
	}

	// unsubscribe to events when component is destroyed
	ngOnDestroy(): void {
		this._destroying$.next(undefined);
		this._destroying$.complete();
	}
}