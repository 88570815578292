import { Component } from '@angular/core';
import { FrameShow } from '../page-frame/page-frame.module';

@Component({
	selector: 'app-unauthorized',
	templateUrl: './unauthorized.component.html',
	styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent {
	show = FrameShow.MAIN;
}
