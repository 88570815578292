import { Component, Directive, HostListener, Injectable, Input, OnInit } from '@angular/core';
import {
	FormControl,
	FormBuilder,
	FormGroup,
	Validator,
	ValidationErrors,
	AsyncValidator,
	NG_ASYNC_VALIDATORS,
	ValidatorFn,
	AbstractControl,
} from '@angular/forms';
import { FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { LocationService } from '../vendor-location/vendor-location.service';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { BehaviorSubject, filter } from 'rxjs';
import { Location, Contact, EquipmentType } from './vendor-location.types';
import { AdministrativeArea, Canada, UnitedStates } from '../geo/administrativeAreas';
import { ComponentCanDeactivate } from '../guard/componentDeactivation';
import { FrameShow } from '../page-frame/page-frame.module';
import { environment } from 'src/environments/environment';
import { CustomerIDValidator, CustomerService } from '../customer/customer.service';
import { Customer } from '../customer/customer.type';

@Component({
	selector: 'app-home',
	templateUrl: './vendor-location.component.html',
	styleUrls: ['./vendor-location.component.scss'],
})
export class VendorLocationComponent implements OnInit, ComponentCanDeactivate {
	Contacts: Contact[] = [];
	Equipment: EquipmentType[] = [];
	administrativeAreas: AdministrativeArea[] = UnitedStates;

	settings = environment.vendor_location;

	submitting = false;

	show: FrameShow = FrameShow.LOADING;

	success = false;

	contactNextID = 0;

	$customer = this.customerService.$lastCustomer;

	locationID = '';

	

	// Forms
	locationForm: FormGroup;
	contactForm: FormGroup;
	equipmentForm: FormGroup;

	public EquipmentTypes: EquipmentType[] = [];

	locationErrorMatcher = new LocationErrorMatcher();
	contactErrorMatcher = new ContactErrorMatcher();
	equipmentTypeErrorMatcher = new EquipmentTypeErrorMatcher();

	filteredEquipmentTypes: BehaviorSubject<EquipmentType[]> = new BehaviorSubject<EquipmentType[]>([]);
	equipmentTypeValidator = new EquipmentTypeValidator();

	constructor(
		private authService: MsalService,
		private msalBroadcastService: MsalBroadcastService,
		private router: Router,
		private service: LocationService,
		private fb: FormBuilder,
		private customerService: CustomerService
	) {}

	private addressPattern = /^\d{1,5}\s\w+(\s\w+)*/;
	private emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

	ngOnInit() {
		// this.customerValidator.SetCustomerService(this.customerService);
		// Check that backend is up

		// Set up location form

		

		this.locationForm = this.fb.group({
			location_id: { value: 'Pending', disabled: true },
			name: ['', Validators.required],
			location_type: { value: 'VENDOR', disabled: true },

			//address: ['', [Validators.required, Validators.required, customerValidator.findValidAddress()]],
			address: ['', [Validators.required,  Validators.pattern(this.addressPattern)]],
			suite: ['', Validators.pattern('^[a-zA-Z0-9]*$')],
			city: ['', [Validators.required, Validators.required, Validators.pattern('^[a-zA-Z]*$')]],
			state: ['', Validators.required],
			zip: ['', [Validators.required, Validators.required, Validators.pattern('^[a-zA-Z0-9- ]*$')]],
			country: ['', Validators.required],
			customer: [
				'',
				{
					asyncValidators: [CustomerIDValidator.createValidator(this.customerService)],
					validators: [Validators.required],
				},
			],
		});

		

		if (this.settings.show_demo_form_data) {
			this.locationForm.setValue({
				location_id: 'Pending',
				name: 'Test Location',
				location_type: 'VENDOR',
				address: 'Test address',
				suite: 'suite',
				city: 'test city',
				state: '',
				zip: '90210',
				country: 'USA',
				customer: '04V5K62',
			});
		}

		// Set up contact form
		this.contactForm = this.fb.group({
			primary: false,
			name: ['test', Validators.required],
			role: ['VENDOR', Validators.required],
			primary_phone: [
				'5555555555',
				[Validators.required,Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)],
			],
			secondary_phone: ['', [Validators.pattern('^[0-9]*$'),Validators.minLength(10), Validators.maxLength(10)]],
			email: ['a@bb.cc', [Validators.required, Validators.email]],
		});

		if (this.settings.show_demo_form_data) {
			this.contactForm.setValue({
				primary: false,
				name: 'test',
				role: 'VENDOR',
				primary_phone: '5555555555',
				secondary_phone: '',
				email: 'a@bb.cc',
			});
		}

		// Set up equipment form
		this.equipmentForm = this.fb.group({
			type: ['', [Validators.required, this.equipmentTypeValidator]],
		});

		// Get Equipment Types from API
		this.service.getEquipmentTypes().subscribe({
			next: (data: EquipmentType[]) => {
				this.EquipmentTypes = data;
				this.filteredEquipmentTypes.next(data);
				this.equipmentTypeValidator.SetValidEquipment(data);

				this.show = FrameShow.MAIN;
			},
			error: (error) => {
				this.show = FrameShow.SERVER_ERROR;
			},
		});

		this.msalBroadcastService.inProgress$
			.pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
			.subscribe(() => {
				// this.authComplete = this.authService.instance.getAllAccounts().length > 0;
			});
	}

	// refresh() {
	// 	this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
	// 		this.router.navigate(['/vendor-location']);
	// 	});
	// }

	@HostListener('window:beforeunload', ['$event'])
	public onBeforeUnload(event: BeforeUnloadEvent): void {
		if (event && !this.canDeactivate()) {
			event.preventDefault();
			event.returnValue = false;
		}
	}

	canDeactivate(): boolean {
		if (this.locationForm.dirty || this.contactDataSource.length > 0 || this.equipmentDataSource.length > 0) {
			return false;
		}

		return true;
	}

	equipmentTypeTextChanged() {
		let value = this.equipmentForm.controls['type'].value;

		if (value === '' || value === null || value === undefined) {
			this.filteredEquipmentTypes.next(this.EquipmentTypes);
			return;
		}

		this.filteredEquipmentTypes.next(
			this.EquipmentTypes.filter((option) => option.description.toLowerCase().includes(value.toLowerCase()))
		);
	}

	@ViewChild(MatTable, { static: false }) table!: MatTable<Contact>;

	displayedColumns: string[] = [
		'primary',
		'name',
		'role',
		'primary_phone',
		'secondary_phone',
		'email',
		'actions',
	];
	contactDataSource = [...this.Contacts];

	addContact() {
		if (this.contactForm.valid) {
			// this.contactForm.markAllAsTouched();
			// this.contactForm.markAsDirty({onlySelf: false})
			this.contactForm.updateValueAndValidity({ onlySelf: false, emitEvent: false });

			this.contactDataSource.push({
				_id: ++this.contactNextID,
				primary: this.contactForm.get('primary').value,
				name: this.contactForm.get('name').value,
				role: this.contactForm.get('role').value,
				primary_phone: this.contactForm.get('primary_phone').value,
				secondary_phone: this.contactForm.get('secondary_phone').value,
				email: this.contactForm.get('email').value,
			});

			this.contactForm.reset();
			this.contactForm.markAsPristine();

			if (this.table !== undefined) {
				this.table.renderRows();
			}

			this.locationForm.markAllAsTouched();
		}
	}

	countryChanged(country: string) {
		if (country === 'USA') {
			this.administrativeAreas = UnitedStates;
		}
		if (country === 'CAN') {
			this.administrativeAreas = Canada;
		}
	}

	makeContactPriority(contactID: number) {
		this.contactDataSource.forEach((value, index) => {
			if (value._id === contactID) {
				value.primary = true;
			} else {
				value.primary = false;
			}
		});
	}

	deleteContact(contact: Contact) {
		if (this.contactForm.pristine) {
			this.contactForm.setValue({
				primary: contact.primary,
				name: contact.name,
				role: contact.role,
				primary_phone: contact.primary_phone,
				secondary_phone: contact.secondary_phone,
				email: contact.email,
			});
		}
		/*this.contactDataSource = this.contactDataSource.filter((value, index) => {
			value._id !== contact._id;
		});*/
		this.contactDataSource = this.contactDataSource.filter(i => i !== contact)
	}

	validateContacts(): boolean {
		if (this.contactDataSource.length < 1) {
			return false;
		}

		if (
			this.contactDataSource.some((val: Contact) => {
				if (val.primary) {
					return true;
				}
				return false;
			})
		) {
			return true;
		}

		return false;
	}

	// @ViewChildren(MatList) listET!: MatList;
	displayedColumnsET: string[] = ['type'];
	equipmentDataSource = [...this.Equipment];

	addEquipmentType() {
		console.log('in add equipment type');
		if (this.equipmentForm.valid) {
			let selected = this.equipmentForm.get('type').value;

			let eq = this.EquipmentTypes.find((t: EquipmentType) => {
				let eqCombo = `(${t.code}) ${t.description}`;
				return eqCombo === selected;
			});

			this.filteredEquipmentTypes.next(this.EquipmentTypes);
			this.equipmentDataSource.push(eq);
			this.equipmentForm.reset();
		}
	}

	createLocation() {
		this.submitting = true;
		this.disableForms();

		let contacts: Contact[] = [];

		this.contactDataSource.forEach((value) => {
			contacts.push({
				_id: value._id,
				primary: value.primary,
				name: value.name,
				role: value.role,
				primary_phone: value.primary_phone,
				secondary_phone: value.secondary_phone,
				email: value.email,
			});
		});

		let equipment: EquipmentType[] = [];

		this.equipmentDataSource.forEach((value) => {
			equipment.push({
				code: value.code,
				description: value.description,
			});
		});

		let location: Location = {
			location_id: this.locationForm.get('location_id').value,
			customer_id: this.locationForm.get('customer').value,
			location_name: this.locationForm.get('name').value,
			address: this.locationForm.get('address').value,
			suite: this.locationForm.get('suite').value,
			city: this.locationForm.get('city').value,
			state: this.locationForm.get('state').value.code,
			zip: this.locationForm.get('zip').value,
			country: this.locationForm.get('country').value,
			contacts: contacts,
			equipment_types: equipment,
		};

		this.service.createLocation(location).subscribe({
			next: (data) => {
				this.locationID = data;
				this.success = true;
				this.clearData();
			},
			error: (error) => {
				console.error('There was an error!', error);
			},
		});
	}

	disableForms() {
		this.locationForm.disable();
		this.contactForm.disable();
		this.equipmentForm.disable();
	}

	enableForms() {
		this.locationForm.enable();
		this.contactForm.enable();
		this.equipmentForm.enable();
	}

	cancel() {
		this.closePopup();
		//window.open('', '_self').close()
	}

	closePopup() {
		// Providing a `null` value to the named outlet
		// clears the contents of the named outlet
		this.router.navigate([{ outlets: { popup: null } }]);
		window.open('', '_parent', '');
		// window.open("about:blank", "_self");
		window.close();
	}

	clearData() {
		this.locationForm.reset();
		this.locationForm.markAsPristine();
		this.contactForm.reset();
		this.contactForm.markAsPristine();
		this.equipmentForm.reset();
		this.equipmentForm.markAsPristine();

		this.contactDataSource = [];
		this.equipmentDataSource = [];
	}


	refresh() {
		this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
			this.router.navigate(['vendor-location']);
		});
	}
}

export class LocationErrorMatcher implements ErrorStateMatcher {
	LocationErrorMatcher() {}

	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null) {
		if (control.valid) {
			return false;
		}

		if (control.touched) {
			return true;
		}

		return false;
	}
}

export class ContactErrorMatcher implements ErrorStateMatcher {
	ContactTypeErrorMatcher() {}

	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null) {
		if (control.valid) {
			return false;
		}

		if (control.touched) {
			return true;
		}
		return false;
	}
}

export class EquipmentTypeErrorMatcher implements ErrorStateMatcher {
	_validEquipment: EquipmentType[] = [];

	EquipmentTypeErrorMatcher(validEquipment: EquipmentType[]) {
		this._validEquipment = validEquipment;
	}

	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null) {
		if (control.value === '' || control.value === null || control.value === undefined) {
			return false;
		}
		// if (!control.touched) {
		//     return false;
		// }

		if (control.valid) {
			return false;
		}

		return true;
	}
}

export class EquipmentTypeValidator implements Validator {
	_validEquipment: EquipmentType[] = [];

	SetValidEquipment(validEquipment: EquipmentType[]) {
		this._validEquipment = validEquipment;
	}

	validate(control: FormControl): ValidationErrors {
		let check = this._validEquipment.find((t: EquipmentType) => {
			let eqCombo = `(${t.code}) ${t.description}`;
			return eqCombo === control.value;
		});

		if (check !== undefined) {
			return null;
		}

		return { 'invalid-equipment-type': 'The equipment type specified is invalid' };
	}


}

 export class customerValidator{

	public static findValidAddress(): ValidatorFn{

		return (c: AbstractControl): ValidationErrors | { [key: string]: boolean } | null => {
            let isDigit = false;
            let isCapsOrSmallLetter = false;
            // let isSmallLetter = false;
            let isSpace = false;
            if ((!/\d/.test(c.value))) {
                // console.log('address must contain digits');
                isDigit = false;
            } else {
                isDigit = true;
            }
            if (!/[A-Za-z]/.test(c.value)) {
                // console.log('address must contain uppercase letter');
                isCapsOrSmallLetter = false;
            } else {
                isCapsOrSmallLetter = true;
            }
            if (!/[ ]/.test(c.value)) {
                // console.log('address must contain space');
                isSpace = false;
            }else {
                isSpace = true;
            }

            if (isDigit && isCapsOrSmallLetter && isSpace === true) {
                // null is required here. otherwise form wonot submit.
                return null;
            }
            return { key: true };

        };
			
	}
 }