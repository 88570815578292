<div class="card-body">
	<header>
		<img src="./assets/images/TJXLogo.png" />
		<h1 class="title">TMS Extensions</h1>
		<h2 class="subtitle">Transportation and Logistics</h2>
	</header>

	<div class="extensions">
		<div *ngIf="!atLeastOneButton">
			<p>
				You do not have access to any of the TMS Extensions. If you require access to this application, submit
				an ARMS request.
			</p>
		</div>

		<span *ngFor="let button of buttons">
			<a mat-raised-button *ngIf="button.display" [routerLink]="button.route">{{ button.title }}</a>
		</span>
	</div>
</div>
