<app-frame *ngIf="!success" 
	header="Vendor Location" 
	[refreshPath]="['/vendor-location']" 
	[show]="show">
	<section class="location">
		<!-- <form [formGroup]="locationForm" class="form-container"> -->
		<form [formGroup]="locationForm">
			<mat-form-field style="grid-area: loc__id">
				<mat-label>Location ID</mat-label>
				<input
					type="text"
					matInput
					formControlName="location_id"
					[errorStateMatcher]="locationErrorMatcher" />
			</mat-form-field>
			<mat-form-field style="grid-area: loctype">
				<mat-label>Location Type</mat-label>
				<input
					type="text"
					formControlName="location_type"
					[errorStateMatcher]="locationErrorMatcher"
					matInput />
			</mat-form-field>
			<mat-form-field style="grid-area: locname" appearance="fill">
				<mat-label>Location Name</mat-label>
				<input
					type="text"
					spellcheck="true"
					matInput
					formControlName="name"
					[errorStateMatcher]="locationErrorMatcher" />
			</mat-form-field>
			<mat-form-field style="grid-area: locaddr" appearance="fill">
				<mat-label>Address</mat-label>
				<input
					type="text"
					spellcheck="true"
					matInput
					formControlName="address"
					[errorStateMatcher]="locationErrorMatcher" />
					<mat-error *ngIf="locationForm.controls['address'].hasError('pattern')">
						<strong>Please enter a valid address.</strong>
					</mat-error>
			</mat-form-field>
			<mat-form-field style="grid-area: locsuit" appearance="fill">
				<mat-label>Suite</mat-label>
				<input type="text" matInput formControlName="suite" [errorStateMatcher]="locationErrorMatcher" />
				<mat-error *ngIf="locationForm.controls['suite'].hasError('pattern')">
					Suite must have <strong>no Special characters.</strong>
				</mat-error>
			</mat-form-field>
			<mat-form-field style="grid-area: loccity" appearance="fill">
				<mat-label>City</mat-label>
				<input
					spellcheck="true"
					type="text"
					matInput
					formControlName="city"
					[errorStateMatcher]="locationErrorMatcher" />
				<mat-error *ngIf="locationForm.controls['city'].hasError('pattern')">
					City must have <strong>no Numbers or Special characters.</strong>
				</mat-error>
			</mat-form-field>
			<mat-form-field style="grid-area: locstat" appearance="fill">
				<mat-label>State</mat-label>
				<mat-select matNativeControl formControlName="state" [errorStateMatcher]="locationErrorMatcher">
					<mat-option *ngFor="let area of administrativeAreas" [value]="area">{{ area.name }}</mat-option>
				</mat-select>
				<mat-hint>For provinces, change country to Canada</mat-hint>
			</mat-form-field>
			<mat-form-field style="grid-area: loc_zip" appearance="fill">
				<mat-label>Zip</mat-label>
				<input type="text" matInput formControlName="zip" [errorStateMatcher]="locationErrorMatcher" />
				<mat-error *ngIf="locationForm.controls['zip'].hasError('pattern')">
					Zip must have <strong>no special characters.</strong>
				</mat-error>
			</mat-form-field>
			<mat-form-field style="grid-area: loccntr" appearance="fill">
				<mat-label>Country</mat-label>
				<mat-select
					matNativeControl
					(valueChange)="countryChanged($event)"
					formControlName="country"
					[errorStateMatcher]="locationErrorMatcher">
					<mat-option value="USA">United States</mat-option>
					<mat-option value="CAN">Canada</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field style="grid-area: loc_cid" appearance="fill">
				<mat-label>Customer Code</mat-label>
				<input matInput formControlName="customer" [errorStateMatcher]="locationErrorMatcher" />
				<mat-error *ngIf="locationForm.controls['customer'].hasError('invalid-customer-code')"
					>Customer Not found</mat-error
				>
				<mat-error *ngIf="locationForm.controls['customer'].hasError('unknown-error')"
					>Unknown error</mat-error
				>
				<mat-icon
					matSuffix
					*ngIf="
						!locationForm.controls['customer'].valid &&
						!locationForm.controls['customer'].pristine &&
						!locationForm.controls['customer'].pending
					"
					class="red"
					>error</mat-icon
				>
				<mat-icon
					matSuffix
					*ngIf="locationForm.controls['customer'].valid && !locationForm.controls['customer'].pending"
					class="green"
					>check</mat-icon
				>
				<mat-hint *ngIf="locationForm.controls['customer'].valid">{{
					($customer | async).description
				}}</mat-hint>
				<mat-spinner matSuffix *ngIf="locationForm.pending" [diameter]="25" color="tjx"></mat-spinner>
			</mat-form-field>
		</form>
	</section>
	<section class="tabs">
		<mat-tab-group color="tjx" mat-stretch-tabs="false">
			<mat-tab label="Contacts ({{ contactDataSource.length }})" matTooltip="You must add at least 1 contact">
				<form [formGroup]="contactForm" class="contact-form">
					<div>
						<mat-checkbox formControlName="primary" color="tjx">Primary</mat-checkbox>
					</div>
					<mat-form-field appearance="fill">
						<mat-label>Name</mat-label>
						<input matInput type="text" [errorStateMatcher]="contactErrorMatcher" formControlName="name" />
					</mat-form-field>

					<mat-form-field class="form-field" appearance="fill">
						<mat-label>Role</mat-label>
						<mat-select matNativeControl [errorStateMatcher]="contactErrorMatcher" formControlName="role">
							<mat-option value="VENDOR">Vendor</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field class="form-field" appearance="fill">
						<mat-label>Primary Phone</mat-label>
						<input matInput [errorStateMatcher]="contactErrorMatcher" formControlName="primary_phone" />
						<mat-error
							*ngIf="
								contactForm.get('primary_phone').invalid &&
								(contactForm.get('primary_phone').dirty || contactForm.get('primary_phone').touched)
							">
							Enter a 10 digit phone number
						</mat-error>
					</mat-form-field>

					<mat-form-field class="form-field" appearance="fill">
						<mat-label>Secondary phone</mat-label>
						<input matInput formControlName="secondary_phone" />
						<mat-error
							*ngIf="
								contactForm.get('secondary_phone').invalid &&
								(contactForm.get('secondary_phone').dirty || contactForm.get('secondary_phone').touched)
							">
							Enter a 10 digit phone number
						</mat-error>
					</mat-form-field>

					<mat-form-field class="form-field" appearance="fill">
						<mat-label>Email</mat-label>
						<input
							type="email"
							matInput
							formControlName="email"
							[errorStateMatcher]="contactErrorMatcher"
							pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
							placeholder="Ex. pat@example.com" />
						<mat-error
							*ngIf="
								contactForm.get('email').invalid &&
								(contactForm.get('email').dirty || contactForm.get('email').touched)
							">
							Enter a valid email address
						</mat-error>
					</mat-form-field>

					<button
						mat-mini-fab
						color="tjx"
						(click)="addContact()"
						[disabled]="submitting || !contactForm.valid">
						<mat-icon>add</mat-icon>
					</button>
				</form>

				<div class="table-container">
					<mat-table
						#table
						*ngIf="contactDataSource.length > 0"
						[dataSource]="contactDataSource"
						class="mat-elevation-z8">
						<!-- Primary -->
						<ng-container matColumnDef="primary">
							<mat-header-cell *matHeaderCellDef>Primary</mat-header-cell>
							<mat-cell *matCellDef="let element" class="primary">
								<mat-icon *ngIf="element.primary" class="yellow">star</mat-icon>
							</mat-cell>
						</ng-container>

						<!-- Name -->
						<ng-container matColumnDef="name">
							<mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
							<mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
						</ng-container>

						<!-- Role -->
						<ng-container matColumnDef="role">
							<mat-header-cell *matHeaderCellDef>Role</mat-header-cell>
							<mat-cell *matCellDef="let element">{{ element.role }}</mat-cell>
						</ng-container>

						<!-- Primary Phone -->
						<ng-container matColumnDef="primary_phone">
							<mat-header-cell *matHeaderCellDef>Primary Phone</mat-header-cell>
							<mat-cell *matCellDef="let element">{{ element.primary_phone }}</mat-cell>
						</ng-container>

						<!-- Secondary Phone -->
						<ng-container matColumnDef="secondary_phone">
							<mat-header-cell *matHeaderCellDef>Secondary Phone</mat-header-cell>
							<mat-cell *matCellDef="let element">{{ element.secondary_phone }}</mat-cell>
						</ng-container>

						<!-- Email -->
						<ng-container matColumnDef="email">
							<mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
							<mat-cell *matCellDef="let element">{{ element.email }}</mat-cell>
						</ng-container>

						<!-- Actions -->
						<ng-container matColumnDef="actions">
							<mat-header-cell *matHeaderCellDef></mat-header-cell>
							<mat-cell *matCellDef="let element" class="actions-col">
								<button
									mat-icon-button
									*ngIf="!element.primary"
									matTooltip="Make Primary"
									[disabled]="submitting"
									(click)="makeContactPriority(element._id)">
									<mat-icon class="yellow material-symbols-outlined">star</mat-icon>
								</button>
								<button
									mat-icon-button
									matTooltip="Delete"
									class="red"
									[disabled]="submitting"
									(click)="deleteContact(element)">
									<!--Element-->
									<mat-icon>delete</mat-icon>
								</button>
							</mat-cell>
						</ng-container>

						<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
						<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
					</mat-table>
				</div>
			</mat-tab>

			<mat-tab label="Equipment ({{ equipmentDataSource.length }})">
				<form [formGroup]="equipmentForm" class="equipment-form">
					<mat-form-field appearance="fill" class="form-field">
						<input
							type="text"
							placeholder="Start typing to select a piece of equipment"
							matInput
							formControlName="type"
							[matAutocomplete]="auto"
							[errorStateMatcher]="equipmentTypeErrorMatcher"
							(input)="equipmentTypeTextChanged()" />
						<mat-error> Please select a valid equipment type </mat-error>
						<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
							<mat-option
								*ngFor="let eqType of filteredEquipmentTypes | async"
								value="({{ eqType.code }}) {{ eqType.description }}">
								({{ eqType.code }}) {{ eqType.description }}
							</mat-option>
						</mat-autocomplete>
						<mat-error> </mat-error>
					</mat-form-field>

					<button
						mat-mini-fab
						color="tjx"
						(click)="addEquipmentType()"
						[disabled]="submitting || !equipmentForm.valid">
						<mat-icon>add</mat-icon>
					</button>
				</form>

				<mat-list role="list">
					<mat-list-item class="mat-list" role="listitem" *ngFor="let eq of equipmentDataSource">
						<mat-card>
							<mat-card-content>({{ eq.code }}) {{ eq.description }}</mat-card-content>
						</mat-card>
					</mat-list-item>
				</mat-list>
			</mat-tab>
		</mat-tab-group>
	</section>
	<section class="actions">
		<div *ngIf="locationForm.valid && contactDataSource.length < 1" class="contact-error">
			You must add a minimum of one contact
		</div>
		<div
			*ngIf="locationForm.valid && contactDataSource.length > 0 && !validateContacts()"
			class="contact-error">
			You must select a primary contact
		</div>
		<mat-spinner *ngIf="submitting" diameter="30" color="tjx" strokeWidth="4"></mat-spinner>
		<button
			mat-raised-button
			color="tjx"
			[disabled]="submitting || !locationForm.valid || !validateContacts()"
			(click)="createLocation()">
			Create Location
		</button>
	</section>
</app-frame>

<app-frame
	*ngIf="success"
	header="Vendor Location - Complete"
	[refreshPath]="['/vendor-location']"
	[show]="show">
	<section class="complete">
		<div class="success">
			<div class="message">
				<mat-icon class="material-symbols-outlined">check_circle</mat-icon>
				<h2>Success!</h2>
			</div>
			<h2>Location ID: {{ locationID }}</h2>
			<p>Your location has been created.</p>
		</div>

		<div class="actions">
			<a mat-raised-button color="tjx" [routerLink]="'/'">Main Menu</a>
			<a mat-raised-button color="tjx" (click)="refresh()">Create another location</a>
		</div>
	</section>
</app-frame>