<main>
	<header>
		<mat-toolbar color="tjx" class="flexSpaceBetween">
			<a class="home-btn" mat-icon-button routerLink="/"><mat-icon>home</mat-icon></a>
			<div class="title">{{ header }}</div>
			<img src="./assets/images/WhiteTJXLogo.png" />
		</mat-toolbar>
	</header>

	<div class="content">
		<ng-container *ngIf="show == frameShowEnum.SERVER_ERROR">
			<ng-container *ngTemplateOutlet="serverErrorTemplate"></ng-container>
		</ng-container>

		<ng-container *ngIf="show == frameShowEnum.LOADING">
			<ng-container *ngTemplateOutlet="loadingTemplate"></ng-container>
		</ng-container>

		<ng-container *ngIf="show == frameShowEnum.AUTH_ERROR">
			<ng-container *ngTemplateOutlet="noAuthTemplate"></ng-container>
		</ng-container>

		<ng-container *ngIf="show == frameShowEnum.MAIN">
			<ng-content></ng-content>
		</ng-container>
	</div>
</main>

<ng-template #loadingTemplate>
	<section class="status-message">
		<mat-spinner></mat-spinner>
		<h2>Loading...</h2>
	</section>
</ng-template>

<ng-template #serverErrorTemplate>
	<section class="status-message">
		<h2>Unable to connect to server</h2>
		<button mat-raised-button *ngIf="refreshPath != null" color="tjx" (click)="refresh()">Retry</button>
	</section>
</ng-template>

<ng-template #noAuthTemplate>
	<section class="status-message">
		You do not have access to Vendor Location extension, please contact the System Administrator for
		assistance
	</section>
</ng-template>
