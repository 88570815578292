import { Component, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';

import { roles } from '../auth-config';
import { RoleGuard } from '../guard/role.guard';

@Component({
	selector: 'app-home',
	styleUrls: ['./home.component.scss'],
	templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
	isLoggedIn = true;
	vendorLocation = true;
	vendorReimbursement = true;
	display: boolean = false;
	atLeastOneButton = false;

	buttons: button[] = [
		{ title: 'Vendor Location', route: '/vendor-location', roles: [roles.LocationWriter], display: false },
		{
			title: 'Vendor Reimbursement',
			route: '/vendor-reimbursement',
			roles: [roles.ReimbursementWriter],
			display: false,
		},
	];

	constructor(
		// private authService: MsalService,
		private roleGuard: RoleGuard,
		private msalBroadcastService: MsalBroadcastService
	) {}

	ngOnInit(): void {
		for (let button of this.buttons) {
			button.display = this.roleGuard.hasRoles(button.roles);
			if (button.display == true) {
				this.atLeastOneButton = true;
			}
		}
		/* code below checks if the msalGuard has signed in user succesfully (if already not authenticated by Azure).
    After user has been authenticated successfully, it checks if the user belongs to the correct ad groups
    and displays the correct extensions

    for local development uncomment console.logs to see more information
    log level can also be changed in app.module
    */
		// this.msalBroadcastService.msalSubject$
		// 	.pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE))
		// 	.subscribe((result: EventMessage) => {
		// 		//console.log('User login failed. Error: ' + result.error);
		// 	});
		// this.msalBroadcastService.msalSubject$
		// 	.pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS))
		// 	.subscribe((result: EventMessage) => {
		// 		//console.log('Login successful' + result);
		// 		// this.setisLoggedIn();
		// 	});
		// this.msalBroadcastService.inProgress$
		// 	.pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
		// 	.subscribe((i: InteractionStatus) => {
		// 		// this.setisLoggedIn();
		// 		if (this.isLoggedIn) {
		// 		}
		// 	});
	}

	// setisLoggedIn() {
	//   this.isLoggedIn = this.authService.instance.getAllAccounts().length > 0;
	// }
}

interface button {
	title: string;
	route: string;
	roles: string[];
	display: boolean;
}
