import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ObservableInput, catchError, delay, map, of, switchMap } from 'rxjs';
import { Customer } from './customer.type';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AbstractControl, AsyncValidatorFn, FormControl, ValidationErrors } from '@angular/forms';

@Injectable({
	providedIn: 'root',
})
export class CustomerService {
	constructor(private http: HttpClient) {}

	public $lastCustomer = new BehaviorSubject<Customer | undefined>(undefined);

	public getCustomerByID(code: string): Observable<Customer> {
		return this.http.get<Customer>(environment.backend_uri + `/Customer?code=${code}`);
	}

	validateCustomerExists(val: string): Observable<ValidationErrors | null> {
		console.log('in validate', val);

		return of(val).pipe(
			delay(1000),
			switchMap((val): ObservableInput<any> => {
				console.log('making call');
				return this.getCustomerByID(val).pipe(
					map((cust) => {
						console.log('customer', cust);
						this.$lastCustomer.next(cust);
					}),
					catchError((err) => {
						console.log('error', err);
						if (err.status == 404) {
							return of({ 'invalid-customer-code': 'The customer was not found' });
						}

						return of({ 'unknown-error': 'There was an error looking up the customer' });
					})
				);
			})
		);
	}
}

export class CustomerIDValidator {
	static createValidator(customerService: CustomerService): AsyncValidatorFn {
		return (control: AbstractControl): Observable<ValidationErrors> => {
			return customerService.validateCustomerExists(control.value);
		};
	}
}
