import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

export enum FrameShow {
	LOADING,
	MAIN,
	SERVER_ERROR,
	AUTH_ERROR,
}

@Component({
	selector: 'app-frame',
	templateUrl: './page-frame.component.html',
	styleUrls: ['./page-frame.component.scss'],
})
export class PageFrameComponent {
	@Input({ required: true }) header: string = '';
	@Input() show: FrameShow = FrameShow.LOADING;
	@Input() refreshPath: string[];

	frameShowEnum = FrameShow;

	constructor(private router: Router) {}

	refresh() {
		this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
			this.router.navigate(this.refreshPath);
		});
	}
}
