import { Injectable } from '@angular/core';
import { Contact, EquipmentType, Location } from './vendor-location.types';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class LocationService {
	public equipmentTypes: EquipmentType[] = [];
	public contacts: Contact[] = [];

	constructor(private http: HttpClient) {}

	getCustomerByID(customerID: string) {
		console.log('getting customer');
		return this.http.get<string>(environment.backend_uri + '/Customer/' + customerID);
	}

	public createLocation(location: Location): Observable<string> {
		return this.http.post<string>(environment.backend_uri + '/VendorLocation', location);
	}

	public getEquipmentTypes(): Observable<EquipmentType[]> {
		return this.http.get<EquipmentType[]>(environment.backend_uri + '/EquipmentType');
	}
}
