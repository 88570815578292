import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Observable, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import {
	MsalBroadcastService,
	MsalGuardConfiguration,
	MsalService,
	MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import { BaseGuard } from './base.guard';

@Injectable()
export class RoleGuard extends BaseGuard {
	constructor(
		@Inject(MSAL_GUARD_CONFIG) protected override msalGuardConfig: MsalGuardConfiguration,
		protected override msalBroadcastService: MsalBroadcastService,
		protected override authService: MsalService,
		protected override location: Location,
		protected override router: Router
	) {
		super(msalGuardConfig, msalBroadcastService, authService, location, router);
	}

	override activateHelper(
		state?: RouterStateSnapshot,
		route?: ActivatedRouteSnapshot
	): Observable<boolean | UrlTree> {
		let result = super.activateHelper(state, route);

		const expectedRoles: string[] = route ? route.data['expectedRoles'] : [];

		return result.pipe(
			concatMap(() => {
				let activeAccount = this.authService.instance.getActiveAccount();

				if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
					activeAccount = this.authService.instance.getAllAccounts()[0];
				}

				if (!activeAccount?.idTokenClaims?.roles) {
					// no roles in token
					return of(this.router.createUrlTree(['/unauthorized']));
				}

				const hasRequiredRole = expectedRoles.some((role: string) =>
					activeAccount?.idTokenClaims?.roles?.includes(role)
				);

				if (!hasRequiredRole) {
					// does not have required roles
					return of(this.router.createUrlTree(['/unauthorized']));
				}

				return of(hasRequiredRole);
			})
		);
	}

	roles(expectedRoles: string[]): string[] | undefined {
		let activeAccount = this.authService.instance.getActiveAccount();

		if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
			activeAccount = this.authService.instance.getAllAccounts()[0];
		}

		return activeAccount?.idTokenClaims?.roles;
	}

	hasRoles(expectedRoles: string[]): boolean {
		let activeAccount = this.authService.instance.getActiveAccount();

		if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
			activeAccount = this.authService.instance.getAllAccounts()[0];
		}

		const hasRequiredRole = expectedRoles.some((role: string) =>
			activeAccount?.idTokenClaims?.roles?.includes(role)
		);

		return hasRequiredRole;
	}
}
