import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { of } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class BackendHealthCheckService {
	constructor(private http: HttpClient) {}

	getHealthCheck(): Observable<boolean> {
		if (environment.no_health_check) {
			return of<boolean>(true);
		}

		// return new Observable<boolean>((observer) => {
		// 	setTimeout(() => {
		// 		observer.next(true);
		// 	}, 5000);
		// });
		return this.http.get(environment.backend_uri + '/health', { responseType: 'text' }).pipe<boolean>(
			map((res) => {
				return res === 'Healthy' ? true : false;
			})
		);
	}
}
