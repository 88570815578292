<h1 mat-dialog-title>{{ data.caption }}</h1>
<div mat-dialog-content>
	<p>{{ data.message }}</p>
</div>

<div mat-dialog-actions *ngIf="data.buttons == MessageBoxButton.OK || data.buttons == null">
	<button mat-raised-button mat-dialog-close="ok" cdkFocusInitial>OK</button>
</div>

<div mat-dialog-actions *ngIf="data.buttons == MessageBoxButton.OKCancel">
	<button mat-raised-button mat-dialog-close="ok">OK</button>
	<button mat-raised-button mat-dialog-close="cancel" cdkFocusInitial>Cancel</button>
</div>

<div mat-dialog-actions *ngIf="data.buttons == MessageBoxButton.AbortRetryIgnore">
	<button mat-raised-button mat-dialog-close="abort">Abort</button>
	<button mat-raised-button mat-dialog-close="retry">Retry</button>
	<button mat-raised-button mat-dialog-close="ignore">Ignore</button>
</div>

<div mat-dialog-actions *ngIf="data.buttons == MessageBoxButton.YesNoCancel">
	<button mat-raised-button mat-dialog-close="yes">Yes</button>
	<button mat-raised-button mat-dialog-close="no">No</button>
	<button mat-raised-button mat-dialog-close="cancel" cdkFocusInitial>Cancel</button>
</div>

<div mat-dialog-actions *ngIf="data.buttons == MessageBoxButton.YesNo">
	<button mat-raised-button mat-dialog-close="yes">Yes</button>
	<button mat-raised-button mat-dialog-close="no">No</button>
</div>

<div mat-dialog-actions *ngIf="data.buttons == MessageBoxButton.RetryCancel">
	<button mat-raised-button mat-dialog-close="retry">Retry</button>
	<button mat-raised-button mat-dialog-close="cancel">Cancel</button>
</div>
