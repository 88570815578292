import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Reimbursement } from './vendor-reimbursement.types';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class ReimbursementService {
	constructor(private http: HttpClient) {}

	getTariffs() {}

	createReimbursements(reimbursements: Reimbursement[]): Observable<void> {
		let reimbursementsCopy: Reimbursement[] = [];

		reimbursements.forEach((r: Reimbursement) => {
			reimbursementsCopy.push({ ...r });
		});

		reimbursementsCopy.forEach((r: Reimbursement) => {
			delete r.ref_error;
			delete r.ref_okay;
		});

		return this.http.post<void>(environment.backend_uri + '/VendorReimbursement', reimbursementsCopy);
	}

	checkVoucherReference(carrierCode: string, voucherReference: string): Observable<boolean> {
		//return this.http.get<boolean>(
			//environment.backend_uri +
				//'/VendorReimbursement/CheckVoucher?carrier_code=' +
				//carrierCode +
				//'&voucher_ref=' +
				//voucherReference
		//);
		return new Observable<true>;
	}

	public createReimbursement(location: Location): Observable<string> {
		return this.http.post<string>(environment.backend_uri + '/VendorReimbursement', location);
	}
}
