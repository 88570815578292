import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VendorLocationComponent } from './vendor-location.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { RouterModule } from '@angular/router';
import { PageFrameModule } from '../page-frame/page-frame.module';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
	declarations: [VendorLocationComponent],
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		PageFrameModule,

		MatProgressSpinnerModule,
		MatInputModule,
		MatButtonModule,
		MatToolbarModule,
		MatIconModule,
		MatSelectModule,
		MatTabsModule,
		MatTableModule,
		MatAutocompleteModule,
		MatListModule,
		MatCardModule,
		MatCheckboxModule,
	],
	exports: [VendorLocationComponent],
})
export class VendorLocationModule {}
