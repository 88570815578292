import { Component } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { Observable } from 'rxjs';

export interface ComponentCanDeactivate {
	canDeactivate: () => boolean | Observable<boolean>;
}

export function pendingChangesGuard(): CanDeactivateFn<ComponentCanDeactivate> {
	return (component: ComponentCanDeactivate) => {
		if (component.canDeactivate()) {
			return true;
		}
		return window.confirm('You have unsaved changes! If you leave, your changes will be lost.');
	};
}
