import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export enum MessageBoxButton {
	OK,
	OKCancel,
	AbortRetryIgnore,
	YesNoCancel,
	YesNo,
	RetryCancel,
}

export interface MessageboxData {
	buttons: MessageBoxButton;
	caption: string;
	message: string;
}

@Component({
	selector: 'app-cmessagebox',
	templateUrl: './messagebox.component.html',
	styleUrls: ['./messagebox.component.scss'],
})
export class MessageboxComponent {
	constructor(
		public dialogRef: MatDialogRef<MessageboxComponent>,
		@Inject(MAT_DIALOG_DATA) public data: MessageboxData
	) {}

	public get MessageBoxButton() {
		return MessageBoxButton;
	}
}
