<app-frame
	*ngIf="!success"
	header="Vendor Reimbursement"
	[refreshPath]="['/vendor-reimbursement']"
	[show]="show">
	<section class="reimbursement">
		<form [formGroup]="form">
			<mat-form-field style="grid-area: car_code" appearance="fill">
				<mat-label>Carrier Code</mat-label>
				<mat-select
					matNativeControl
					formControlName="carrier_code"
					[errorStateMatcher]="voucherFormErrorMatcher">
					<mat-option *ngFor="let code of carrierCodes" [value]="code">{{ code }}</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field style="grid-area: banner__" appearance="fill">
				<mat-label>Banner</mat-label>
				<mat-select matNativeControl formControlName="banner" [errorStateMatcher]="voucherFormErrorMatcher">
					<mat-option *ngFor="let banner of banners" [value]="banner">{{ banner.name }}</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field style="grid-area: vouchref" appearance="fill">
				<mat-label>Voucher Reference</mat-label>
				<input
					type="text"
					spellcheck="true"
					name="voucherRef"
					placeholder="ex: 2TJX2022A"
					matInput
					formControlName="voucher_ref"
					[errorStateMatcher]="voucherFormErrorMatcher" />
					<mat-error *ngIf="form.controls['voucher_ref'].hasError('pattern')">
						Voucher Reference must be <strong>a numbers and letters</strong>
					</mat-error>
			</mat-form-field>

			<mat-form-field style="grid-area: eff_date" appearance="fill">
				<mat-label>Effective Date</mat-label>
				<input
					name="effectiveDate"
					placeholder="MM/DD/YYYY"
					matInput
					(click)="effectiveDatePicker.open()"
					[min]="minDate"
					[max]="maxDate"
					[matDatepicker]="effectiveDatePicker"
					formControlName="effective_date"
					[errorStateMatcher]="voucherFormErrorMatcher" />
				<mat-datepicker-toggle matSuffix [for]="effectiveDatePicker"></mat-datepicker-toggle>
				<mat-datepicker #effectiveDatePicker></mat-datepicker>
				<mat-error *ngIf="form.controls['effective_date'].hasError('matDatepickerMax')">
					Date cannot be in the future
				</mat-error>
				<mat-error *ngIf="form.controls['effective_date'].hasError('matDatepickerMin')">
					Date cannot be before <strong>Feb 1, 2020</strong>
				</mat-error>
			</mat-form-field>

			<mat-form-field style="grid-area: origin__" appearance="fill">
				<mat-label>Origin</mat-label>
				<mat-select matNativeControl formControlName="origin" [errorStateMatcher]="voucherFormErrorMatcher">
					<mat-option *ngFor="let origin of origins" [value]="origin">{{ origin }}</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field style="grid-area: destinat" appearance="fill">
				<mat-label>Destination</mat-label>
				<mat-select
					matNativeControl
					formControlName="destination"
					[errorStateMatcher]="voucherFormErrorMatcher">
					<mat-option *ngFor="let dest of destinations" [value]="dest">{{ dest }}</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field style="grid-area: orcvenid" appearance="fill">
				<mat-label>Oracle Vendor ID</mat-label>
				<input
					type="text"
					spellcheck="false"
					placeholder="ex: 12227"
					matInput
					formControlName="oracle_vendor_id"
					[errorStateMatcher]="voucherFormErrorMatcher" />
				<mat-error *ngIf="form.controls['oracle_vendor_id'].hasError('pattern')">
					Oracle Vendor ID must be <strong>a number</strong>
				</mat-error>
			</mat-form-field>

			<mat-form-field style="grid-area: gl__acct" appearance="fill">
				<mat-label>GL Account Number</mat-label>
				<input
					type="text"
					spellcheck="true"
					name="glAccountNumber"
					placeholder="ex: 108108096761618065050000000"
					matInput
					formControlName="gl_account"
					[errorStateMatcher]="voucherFormErrorMatcher" />
				<mat-error *ngIf="form.controls['gl_account'].hasError('pattern')">
					GL Account # must be <strong>a number</strong>
				</mat-error>
			</mat-form-field>

			<mat-form-field style="grid-area: memo____" appearance="fill">
				<mat-label>Memo</mat-label>
				<textarea
					type="text"
					spellcheck="true"
					maxLength="200"
					#memoInput
					name="memo"
					rows="4"
					placeholder="ex. Approved by John Doe"
					matInput
					formControlName="memo"
					[errorStateMatcher]="voucherFormErrorMatcher"></textarea>
				<mat-hint class="memo-hint"
					>Characters Remaining: {{ 200 - memoInput.value.length }} / {{ 200 }}</mat-hint
				>
			</mat-form-field>

			<mat-form-field style="grid-area: chg_code" appearance="fill">
				<mat-label>Charge Code</mat-label>
				<mat-select
					matNativeControl
					formControlName="charge_code"
					(selectionChange)="changeChargeCode(form.controls['charge_code'].value)"
					[errorStateMatcher]="voucherFormErrorMatcher">
					<mat-option *ngFor="let code of chargeCodes" [value]="code">{{ code }}</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field style="grid-area: chg_amnt" appearance="fill">
				<mat-label>Charged Amount</mat-label>
				<span matPrefix>USD $ &nbsp;</span>
				<input
					type="number"
					name="chargedAmt"
					placeholder="ex: 550"
					min="0"
					matInput
					formControlName="charge_amount"
					[errorStateMatcher]="voucherFormErrorMatcher" />
				<mat-error *ngIf="form.controls['charge_amount'].hasError('min')">
					Charged Amount must be at least <strong>0</strong>
				</mat-error>
				<mat-error *ngIf="form.controls['charge_amount'].hasError('max')">
					Must be less than <strong>$500,000</strong>
				</mat-error>
				<mat-hint
					class="warning-msg"
					*ngIf="
						form.controls['charge_amount'].value > settings.charge_warning &&
						form.controls['charge_amount'].value < 500000
					">
					Warning: amount over
					<strong>{{ chargeWarningString }}</strong
					>.
				</mat-hint>
			</mat-form-field>

			<button
				mat-raised-button
				class="add-button"
				color="tjx"
				[disabled]="!form.valid || submitting || reimbursements.length >= settings.max_reimbursements"
				(click)="addReimbursementClick()">
				Add to batch<span *ngIf="reimbursements.length >= settings.max_reimbursements"> (limit reached)</span>
			</button>
			<button
				mat-raised-button
				class="clear-button"
				color="tjx"
				[disabled]="submitting"
				(click)="clearReimbursement()">
				Clear this form
			</button>
		</form>

		<div class="batch">
			<div class="header" *ngIf="reimbursements.length < 1">No Reimbursements in batch</div>
			<div class="header" *ngIf="reimbursements.length > 0">
				{{ reimbursements.length }} Reimbursements in batch
			</div>
			<mat-table
				#table
				*ngIf="reimbursements.length > 0"
				[dataSource]="reimbursements"
				style="width: 100%"
				class="mat-elevation-z8">
				<ng-container matColumnDef="banner">
					<mat-header-cell *matHeaderCellDef>Banner</mat-header-cell>
					<mat-cell *matCellDef="let element"
						><span class="truncate">{{ element.banner.name }}</span></mat-cell
					>
				</ng-container>

				<ng-container matColumnDef="voucherRef">
					<mat-header-cell *matHeaderCellDef>Voucher Ref.</mat-header-cell>
					<mat-cell class="cell-voucher-ref" *matCellDef="let element">
						<!-- show spinner while waiting for response from server -->
						<mat-spinner
							*ngIf="element.ref_okay == null"
							diameter="20"
							color="tjx"
							strokeWidth="2"
							class="status"
							matTooltip="Checking Voucher Reference"></mat-spinner>

						<!-- Show checkmark if reference is valid -->
						<mat-icon
							*ngIf="element.ref_okay == true"
							class="ref-check status"
							matTooltip="Voucher Reference Valid"
							>check</mat-icon
						>
						<!-- Show error icon if reference is invalid -->
						<mat-icon
							*ngIf="element.ref_okay == false && element.ref_error !== true"
							class="ref-error material-symbols-outlined status"
							matTooltip="Voucher Reference Already Exists"
							>error</mat-icon
						>
						<button
							mat-icon-button
							*ngIf="element.ref_error == true"
							matTooltip="Error checking voucher reference. Click to retry."
							(click)="checkVoucherRef(element)">
							<mat-icon class="material-symbols-outlined">refresh</mat-icon>
						</button>
						<div>{{ element.voucher_ref }}</div></mat-cell
					>
				</ng-container>

				<ng-container matColumnDef="effectiveDate">
					<mat-header-cell *matHeaderCellDef>Effective Date</mat-header-cell>
					<mat-cell *matCellDef="let element"
						><span class="truncate">{{ element.effective_date | date : "mediumDate" }}</span></mat-cell
					>
				</ng-container>

				<ng-container matColumnDef="oracleVendorID">
					<mat-header-cell *matHeaderCellDef>Oracle Vendor ID</mat-header-cell>
					<mat-cell *matCellDef="let element"
						><span class="truncate">{{ element.oracle_vendor_id }}</span></mat-cell
					>
				</ng-container>

				<ng-container matColumnDef="glAccountNumber">
					<mat-header-cell *matHeaderCellDef>GL Account</mat-header-cell>
					<mat-cell *matCellDef="let element"
						><span class="truncate">{{ element.gl_account }}</span></mat-cell
					>
				</ng-container>

				<ng-container matColumnDef="memo">
					<mat-header-cell *matHeaderCellDef>Memo</mat-header-cell>
					<mat-cell matTooltip="{{ element.memo }}" *matCellDef="let element"
						><span class="truncateEllipsis">{{ element.memo }}</span></mat-cell
					>
				</ng-container>

				<ng-container matColumnDef="chargeCode">
					<mat-header-cell *matHeaderCellDef>Charge Code</mat-header-cell>
					<mat-cell *matCellDef="let element"
						><span class="truncate">{{ element.charge_code }}</span></mat-cell
					>
				</ng-container>

				<ng-container matColumnDef="chargedAmt">
					<mat-header-cell *matHeaderCellDef>Charged Amount</mat-header-cell>
					<mat-cell *matCellDef="let element"
						><span class="truncate">{{
							element.charge_amount | currency : "USD" : "symbol" : "1.2-2"
						}}</span></mat-cell
					>
				</ng-container>

				<ng-container matColumnDef="actions">
					<mat-header-cell *matHeaderCellDef></mat-header-cell>
					<mat-cell class="row-actions" *matCellDef="let i = index">
						<button [disabled]="submitting" mat-icon-button class="editButton" (click)="editReimbursement(i)">
							<mat-icon>edit</mat-icon>
						</button>
						<button
							[disabled]="submitting"
							mat-icon-button
							class="deleteButton"
							(click)="deleteReimbursement(i)">
							<mat-icon>delete</mat-icon>
						</button>
					</mat-cell>
				</ng-container>

				<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
				<mat-row
					*matRowDef="let row; columns: displayedColumns"
					[ngClass]="{ pink: row.ref_error == true || row.ref_okay == false }"></mat-row>
			</mat-table>
		</div>

		<div class="actions">
			<mat-spinner *ngIf="submitting" diameter="30" color="tjx" strokeWidth="4"></mat-spinner>
			<button
				mat-raised-button
				*ngIf="reimbursements.length < 1"
				[disabled]="reimbursements.length < 1 || !allReferencesOK"
				color="tjx">
				Submit Reimbursements
			</button>
			<button
				mat-raised-button
				*ngIf="reimbursements.length > 0"
				color="tjx"
				[disabled]="submitting || !allReferencesOK"
				(click)="submitBatchClick()">
				Submit {{ reimbursements.length }} Reimbursements
			</button>
		</div>
	</section>
</app-frame>

<app-frame
	*ngIf="success"
	header="Vendor Reimbursement - Complete"
	[refreshPath]="['/vendor-reimbursement']"
	[show]="show">
	<section class="complete">
		<div class="success">
			<div class="message">
				<mat-icon class="material-symbols-outlined">check_circle</mat-icon>
				<h2>Success!</h2>
			</div>
			<p>Your reimbursements have been submitted.</p>
		</div>

		<div class="actions">
			<a mat-raised-button color="tjx" [routerLink]="'/'">Main Menu</a>
			<a mat-raised-button color="tjx" (click)="refresh()">Submit another batch</a>
		</div>
	</section>
</app-frame>
