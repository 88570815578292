import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VendorReimbursementComponent } from './vendor-reimbursement.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatNativeDateModule, MAT_DATE_LOCALE,MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { PageFrameModule } from '../page-frame/page-frame.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
	declarations: [VendorReimbursementComponent],
	imports: [
		CommonModule,
		FormsModule,
		BrowserModule,
		RouterModule,
		MatProgressSpinnerModule,
		MatIconModule,
		MatTableModule,
		MatNativeDateModule,
		MatDatepickerModule,
		MatMomentDateModule,
		ReactiveFormsModule,
		MatSelectModule,
		MatTooltipModule,
		MatToolbarModule,
		MatInputModule,
		MatButtonModule,
		MatDialogModule,
		MatSnackBarModule,
		PageFrameModule,
	],
	providers: [
		{provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        {
             provide: DateAdapter,
             useClass: MomentDateAdapter,
             deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
	],
	exports: [VendorReimbursementComponent],
})
export class VendorReimbursementModule {}
