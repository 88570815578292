import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { VendorReimbursementComponent } from './vendor-reimbursement/vendor-reimbursement.component';
import { VendorLocationComponent } from './vendor-location/vendor-location.component';

import { BrowserUtils, LogLevel } from '@azure/msal-browser';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { pendingChangesGuard } from './guard/componentDeactivation';
import { RoleGuard } from './guard/role.guard';
import { BaseGuard } from './guard/base.guard';
import { roles } from './auth-config';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

const routes: Routes = [
	{
		path: 'vendor-reimbursement',
		component: VendorReimbursementComponent,
		canActivate: [RoleGuard],
		data: {
			expectedRoles: [roles.ReimbursementWriter],
		},
		canDeactivate: [pendingChangesGuard()],
	},
	{
		path: 'vendor-location',
		component: VendorLocationComponent,
		canActivate: [RoleGuard],
		data: {
			expectedRoles: [roles.LocationWriter],
		},
		canDeactivate: [pendingChangesGuard()],
	},
	{
		// Dedicated route for redirects
		path: 'auth',
		component: MsalRedirectComponent,
	},
	{
		// Dedicated route for redirects
		path: '.auth/login/done',
		component: MsalRedirectComponent,
	},
	{
		// Dedicated route for redirects
		path: '.auth/login/aad/callback',
		component: MsalRedirectComponent,
	},
	{
		// Dedicated route for redirects
		path: 'unauthorized',
		component: UnauthorizedComponent,
	},
	{ path: '', pathMatch: 'full', component: HomeComponent, canActivate: [BaseGuard] },
	{ path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			//Don't perform initial navigation in iframes or popups
			// MSAL uses a hidden iframe to acquire or renew tokens silently in the background
			initialNavigation:
				!BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled',
		}),
	],
	exports: [RouterModule],
	providers: [],
})
export class AppRoutingModule {}

export function loggerCallback(logLevel: LogLevel, message: string) {
	console.log(message);
}