import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule } from '@angular/material/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

// import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

import {
	MsalBroadcastService,
	MsalInterceptorConfiguration,
	MsalModule,
	MsalService,
	MSAL_GUARD_CONFIG,
	MSAL_INSTANCE,
	MSAL_INTERCEPTOR_CONFIG,
	MsalGuardConfiguration,
	MsalRedirectComponent,
	ProtectedResourceScopes,
	MsalInterceptor,
} from '@azure/msal-angular';
import { msalConfig, loginRequest, protectedResources } from './auth-config';

import { LocationService } from './vendor-location/vendor-location.service';
import { ReimbursementService as ReimbursementService } from './vendor-reimbursement/vendor-reimbursement.service';
import { BackendHealthCheckService } from './services/backend-health-check.service';
import { VendorLocationModule } from './vendor-location/vendor-location.module';
import { VendorReimbursementModule } from './vendor-reimbursement/vendor-reimbursement.module';
import { PageFrameModule } from './page-frame/page-frame.module';
import { MessageboxModule } from './messagebox/messagebox.module';
import { HomeModule } from './home/home.module';
import { BaseGuard } from './guard/base.guard';
import { RoleGuard } from './guard/role.guard';
import { IPublicClientApplication, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
	declarations: [AppComponent, UnauthorizedComponent],
	imports: [
		AppRoutingModule,
		BrowserModule,
		MsalModule,
		HttpClientModule,
		FormsModule,
		BrowserAnimationsModule,
		MatNativeDateModule,
		ReactiveFormsModule,
		HomeModule,
		VendorLocationModule,
		VendorReimbursementModule,
		PageFrameModule,
		MessageboxModule,
		MatButtonModule,
	],
	providers: [
		LocationService,
		ReimbursementService,
		BackendHealthCheckService,
		BaseGuard,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MsalInterceptor,
			multi: true,
		},
		{
			provide: MSAL_INSTANCE,
			useFactory: MSALInstanceFactory,
		},
		{
			provide: MSAL_GUARD_CONFIG,
			useFactory: MSALGuardConfigFactory,
		},
		{
			provide: MSAL_INTERCEPTOR_CONFIG,
			useFactory: MSALInterceptorConfigFactory,
		},
		MsalService,
		RoleGuard,
		MsalBroadcastService,
	],
	bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}

export function MSALInstanceFactory(): IPublicClientApplication {
	// Create an instance of PublicClientApplication
	const msalInstance = new PublicClientApplication(msalConfig);

	// msalInstance
	// 	.handleRedirectPromise()
	// 	.then((authResult) => {
	// 		// Check if user signed in
	// 		const account = msalInstance.getActiveAccount();
	// 		if (!account) {
	// 			// redirect anonymous user to login page
	// 			msalInstance.loginRedirect();
	// 		}
	// 	})
	// 	.catch((err) => {
	// 		// TODO: Handle errors
	// 		console.log(err);
	// 	});

	return msalInstance;
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
	return {
		interactionType: InteractionType.Redirect,
		authRequest: loginRequest,
	};
}

// The MSAL Angular wrapper provides the HTTP interceptor, which will automatically acquire access tokens silently and attach
// them to the HTTP requests to APIs. Does what acquireTokenSilent method does.
// In provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor,
// Resources (GRAPH) and scope configuration
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
	const protectedResourceMap = new Map<string, Array<string>>();

	protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['User.Read']);
	protectedResourceMap.set(protectedResources.apiList.endpoint, protectedResources.apiList.scopes);

	return {
		interactionType: InteractionType.Redirect,
		protectedResourceMap,
	};
}
