

export const environment = {
	production: false,
	clientId: '4a490a17-0dc3-4d28-926b-303147054ad5',
	redirectUri: 'https://tms-extension.stg.tjxcloud.com/',
	authority: 'https://login.microsoftonline.com/2242945a-4ab9-4132-840e-cce1c66e31bb',

	backend_uri: 'https://tmsnpeus2stg01server01.azurewebsites.net',
	scopes: ['User.Read'],
	backend_scope: ['https://tms-extension.stg.tjxcloud.com/user_impersonation'],

	no_health_check: true,

	vendor_reimbursement: {
		show_demo_form_data: false, // Show demo data when a form is loaded
		clear_form_data: true, // Clear form data after submission
		charge_warning: 100000,
		charge_max: 500000,
		max_reimbursements: 10, // Maximum number of reimbursements allowed in batch
	},

	vendor_location: {
		show_demo_form_data: false, // Show demo data when a form is loaded
		clear_form_data: true, // Clear form data after submission
	},
};
