import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { ReimbursementService as ReimbursementService } from './vendor-reimbursement.service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { Reimbursement, Banner } from './vendor-reimbursement.types';
import { ComponentCanDeactivate } from '../guard/componentDeactivation';
import { MatDialog } from '@angular/material/dialog';
import { MessageboxComponent, MessageBoxButton } from '../messagebox/messagebox.component';
import { formatCurrency } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BackendHealthCheckService } from '../services/backend-health-check.service';
import { Router } from '@angular/router';
import { FrameShow } from '../page-frame/page-frame.module';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-vendorreimbursement',
	templateUrl: './vendor-reimbursement.component.html',
	styleUrls: ['./vendor-reimbursement.component.scss'],
})
export class VendorReimbursementComponent implements OnInit, ComponentCanDeactivate {
	@ViewChild(MatTable, { static: false }) table!: MatTable<Reimbursement>;

	show: FrameShow = FrameShow.LOADING;

	reimbursements: Reimbursement[] = [];
	displayedColumns: string[] = [
		'banner',
		'voucherRef',
		'effectiveDate',
		'oracleVendorID',
		'glAccountNumber',
		'memo',
		'chargeCode',
		'chargedAmt',
		'actions',
	];

	success = false;

	voucherFormErrorMatcher = new VoucherFormErrorMatcher();

	// Form
	form: FormGroup;

	// Date Picker Config
	// 1st Feb 2020 < Date < Present
	minDate = new Date(2020, 1, 1);
	maxDate = new Date();

	settings = environment.vendor_reimbursement;
	chargeWarningString = formatCurrency(this.settings.charge_warning, 'en-US', '$', 'USD', '1.0-0');

	carrierCodes: string[] = ['PREPAID'];

	//Banners for charge code
	banners: Banner[] = [
		{ code: 'TJMAXX', name: 'T.J.Maxx' },
		{ code: 'MARSHALLS', name: 'Marshalls' },
		{ code: 'HOMEGOODS', name: 'HomeGoods' },
	];

	// Status variables
	// ready = false;
	// backendDown = false;
	submitting = false;
	allReferencesOK = true;

	chargeCodes: string[] = ['V_FREIGHT_REIMB', 'NON_TMS_CARRIER', 'ZZZZ'];

	origins: string[] = ['VENDOR-GENERIC'];

	destinations: string[] = ['DC-GENERIC'];

	constructor(
		private service: ReimbursementService,
		private fb: FormBuilder,
		public dialog: MatDialog,
		private snackBar: MatSnackBar,
		private health: BackendHealthCheckService,
		private router: Router
	) {}

	ngOnInit() {
		this.health.getHealthCheck().subscribe({
			next: (ok) => {
				if (ok) {
					this.show = FrameShow.MAIN;
				}

				if (!ok) {
					this.show = FrameShow.SERVER_ERROR;
				}
			},
			error: (error) => {
				this.show = FrameShow.SERVER_ERROR;
			},
		});

		// Set up location form
		this.form = this.fb.group({
			carrier_code: ['', Validators.required],
			banner: ['', Validators.required],
			voucher_ref: ['', [Validators.required, Validators.required, Validators.pattern('^[a-zA-Z0-9]*$')]],
			effective_date: ['', Validators.required],
			origin: ['', Validators.required],
			destination: ['', Validators.required],
			oracle_vendor_id: ['', [Validators.required, Validators.required, Validators.pattern('^[0-9]*$')]],
			gl_account: ['', Validators.pattern('^[0-9]*$')],
			memo: ['', Validators.required],
			charge_code: ['', Validators.required],
			charge_amount: new FormControl<number>(0, [
				Validators.required,
				Validators.min(0),
				Validators.max(this.settings.charge_max),
			]),
		});

		if (this.settings.show_demo_form_data) {
			this.form.setValue({
				carrier_code: 'PREPAID',
				banner: '',
				voucher_ref: '911',
				effective_date: '2021-01-01',
				origin: 'VENDOR-GENERIC',
				destination: 'DC-GENERIC',
				oracle_vendor_id: '123',
				gl_account: '123',
				memo: 'Test',
				charge_code: 'V_FREIGHT_REIMB',
				charge_amount: 4.0,
			});
		}
	}

	refresh() {
		this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
			this.router.navigate(['/vendor-reimbursement']);
		});
	}

	@HostListener('window:beforeunload', ['$event'])
	public onBeforeUnload(event: BeforeUnloadEvent): void {
		if (event && !this.canDeactivate()) {
			event.preventDefault();
			event.returnValue = false;
		}
	}

	canDeactivate(): boolean {
		if (this.form.dirty || this.reimbursements.length > 0) {
			return false;
		}

		return true;
	}

	// GL Account scenarios
	// 1. chargeCode == ZZZZ, GL Account mandatory (value needs to be validated)
	// 2. chargeCode != ZZZZ, GL Optional and empty (does not need to be validated)
	// 3. chargeCode != ZZZZ, GL Optional and not empty (value needs to be validated)

	addReimbursementClick() {
		this.form.markAllAsTouched();
		this.form.controls['gl_account'].updateValueAndValidity();

		if (this.form.invalid) {
			return;
		}

		var chargedAmount = this.form.value['charge_amount'];

		if (chargedAmount > this.settings.charge_warning) {
			const dialogRef = this.dialog.open(MessageboxComponent, {
				data: {
					buttons: MessageBoxButton.YesNo,
					caption: 'Confirm Charged Amount',
					message: `Confirm you intended to enter a charge amount of ${formatCurrency(
						chargedAmount,
						'en-US',
						'$'
					)}`,
				},
			});

			dialogRef.afterClosed().subscribe((result: string) => {
				if (result === 'yes') {
					this.addReimbursement();
					// this.clearReimbursement(true);
				}
			});
		}

		if (chargedAmount <= this.settings.charge_warning) {
			this.addReimbursement();
		}

		if (this.settings.clear_form_data) {
			this.clearReimbursement(true);
		}
	}

	private addReimbursement() {
		const reimbursement = {
			carrier_code: this.form.controls['carrier_code'].value,
			banner: this.form.controls['banner'].value,
			voucher_ref: this.form.controls['voucher_ref'].value,
			effective_date: this.form.controls['effective_date'].value,
			origin: this.form.controls['origin'].value,
			destination: this.form.controls['destination'].value,
			oracle_vendor_id: this.form.controls['oracle_vendor_id'].value,
			gl_account: this.form.controls['gl_account'].value,
			memo: this.form.controls['memo'].value,
			charge_code: this.form.controls['charge_code'].value,
			charge_amount: this.form.controls['charge_amount'].value,
		} as Reimbursement;

		this.reimbursements.unshift(reimbursement);

		if (this.table) {
			this.table.renderRows();
		}

		this.checkVoucherRef(reimbursement);
	}

	checkVoucherRef(reimbursement: Reimbursement) {
		reimbursement.ref_okay = true;
		reimbursement.ref_error = false;

		this.service.checkVoucherReference(reimbursement.carrier_code, reimbursement.voucher_ref).subscribe({
			next: (ok) => {
				reimbursement.ref_okay = ok;
				reimbursement.ref_error = false;
				// this.checkAllVoucherReferencesOK();
			},
			error: (err) => {
				reimbursement.ref_okay = false;
				reimbursement.ref_error = true;
				// this.checkAllVoucherReferencesOK();

				this.snackBar.open('Error checking voucher reference', '', { duration: 5000 });
			},
		});
	}

	checkAllVoucherReferencesOK(): boolean {
		return this.reimbursements.every((r: Reimbursement) => {
			if (r.ref_okay) {
				return true;
			}
			return false;
		});
	}

	clearReimbursement(force: boolean = false) {
		if (force) {
			this.form.reset();
			this.form.markAsPristine();
			return;
		}

		const dialogRef = this.dialog.open(MessageboxComponent, {
			data: {
				buttons: MessageBoxButton.YesNo,
				caption: 'Confirm',
				message: 'Are you sure you want to clear the form?',
			},
		});

		dialogRef.afterClosed().subscribe((result: string) => {
			if (result === 'yes') {
				this.form.reset();
				this.form.markAsPristine();
			}
		});
	}

	changeChargeCode(code: string) {
		if (code === 'ZZZZ') {
			this.form.controls['gl_account'].addValidators([Validators.required]);
		} else {
			this.form.controls['gl_account'].removeValidators([Validators.required]);
		}

		this.form.controls['gl_account'].updateValueAndValidity();
	}

	deleteReimbursement(index: number, force: boolean = false) {
		if (!force) {
			const dialogRef = this.dialog.open(MessageboxComponent, {
				data: {
					buttons: MessageBoxButton.YesNo,
					caption: 'Confirm',
					message: 'Are you sure you want to delete the reimbursement?',
				},
				
			});

			dialogRef.afterClosed().subscribe((result: string) => {
				if (result === 'no') {
					return;
				}
			});
			dialogRef.afterClosed().subscribe((result: string) => {
				if (result === 'yes') {
					this.reimbursements.splice(index, 1);
					// this.reimbursements.entries.bind;
					this.table.renderRows();
					return;
				}
			});
		}

		
		//this.reimbursements.splice(index, 1);
		// this.reimbursements.entries.bind;
		//this.table.renderRows();

		// const maxEntries = 100;
		// const addBtn = document.getElementById('addButton') as HTMLButtonElement | null;
		// const enabled = maxEntries - 1;
		// if (addBtn != null && this.reimbursements.length == enabled) {
		// 	addBtn.disabled = false;
		// }
	}

	finalDeleteReimbursement

	editReimbursement(index: number) {
		if (!this.form.pristine) {
			const dialogRef = this.dialog.open(MessageboxComponent, {
				data: {
					buttons: MessageBoxButton.YesNo,
					caption: 'Confirm',
					message: 'The current form is not empty, do you want to clear it?',
				},
			});

			dialogRef.afterClosed().subscribe((result: string) => {
				if (result === 'yes') {
					this.clearReimbursement(true);
					this.moveReimbursementToForm(index);
					this.deleteReimbursement(index, true);
				}
			});
		}

		if (this.form.pristine) {
			this.clearReimbursement(true);
			this.moveReimbursementToForm(index);
			this.deleteReimbursement(index, true);
		}

		this.table.renderRows();
	}

	private moveReimbursementToForm(index: number) {
		var entity = this.reimbursements[index];
		delete entity.ref_okay;
		delete entity.ref_error;
		this.form.setValue(entity);
	}

	/* Checks the amount of VRF entries in the grid and disables form fields and addButton if needed
     Grid has maximum amount of entries:
       maxEntries = 100 - this value may be changed in the future
     If updating this value, please edit the maxEntries and enabled values in deleteVR()
  */
	checkVRAmount() {
		const addBtn = document.getElementById('addButton') as HTMLButtonElement | null;
		const maxEntries = 100;
		if (addBtn != null && this.reimbursements.length == maxEntries) {
			addBtn.disabled = true;
		}
	}

	submitBatchClick() {
		var refError = this.reimbursements.every((reimbursement) => {
			if (reimbursement.ref_okay == null || reimbursement.ref_okay === false) {
				return true;
			}
			return false;
		});

		if (refError) {
			const dialogRef = this.dialog.open(MessageboxComponent, {
				data: {
					buttons: MessageBoxButton.OK,
					caption: 'Error',
					message: `One or more reimbursements have voucher reference errors`,
				},
			});

			return;
		}

		const dialogRef = this.dialog.open(MessageboxComponent, {
			data: {
				buttons: MessageBoxButton.YesNo,
				caption: 'Confirm',
				message: `Submit batch of ${this.reimbursements.length} reimbursements?`,
			},
		});

		dialogRef.afterClosed().subscribe((result: string) => {
			if (result !== 'yes') {
				this.submitting = false;
				this.form.enable();
				return;
			}

			if (result === 'yes') {
				this.submitting = true;
				this.form.disable();
				this.submitBatch();
			}
		});
	}

	submitBatch() {
		this.service.createReimbursements(this.reimbursements).subscribe({
			next: (response) => {
				this.success = true;
				this.form.markAsPristine;
				this.reimbursements = [];
			},
			error: (error) => {
				this.submitting = false;
				this.form.enable();
				this.snackBar.open('Error submitting reimbursements', 'Close', {
					duration: 5000,
				});
			},
		});
	}
}

export class VoucherFormErrorMatcher implements ErrorStateMatcher {
	// EquipmentTypeErrorMatcher(validEquipment: EquipmentType[]) {
	// 	this._validEquipment = validEquipment;
	// }

	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null) {
		// if (control.value === '' || control.value === null || control.value === undefined) {
		if (!control.touched) {
			return false;
		}

		if (control.valid) {
			return false;
		}

		return true;
	}
}
