import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FrameShow, PageFrameComponent } from './page-frame/page-frame.component';
import { RouterModule } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
	declarations: [PageFrameComponent],
	imports: [
		CommonModule,
		RouterModule,
		MatProgressSpinnerModule,
		MatToolbarModule,
		MatIconModule,
		MatButtonModule,
	],
	exports: [PageFrameComponent],
})
export class PageFrameModule {}
export { FrameShow };
